import { Skill } from '../../contracts/skill'

export class SkillTagsVm {
  constructor(
    private focusSkillIds: string[],
    private skills: Skill[],
    private characterLimit?: number
  ) {}

  ellipsisCharLength = 3
  focusStarCharLength = 3
  hiddenCountCharLength = 5
  separatorCharLength = 1
  spacingCharLength = 3

  get mappedSkills() {
    const characterLimit = this.characterLimit

    if (characterLimit) {
      let count = 1
      let runningTotal = 0

      return this.sortedSkills.reduce<{ show: Skill[]; hide: Skill[] }>(
        (acc, skill) => {
          let length = skill.name.length + this.spacingCharLength
          if (this.isFocusSkill(skill)) length += this.focusStarCharLength
          if (this.skillCount > 1) length += this.separatorCharLength

          const extra =
            count != this.skillCount ? this.hiddenCountCharLength : 0

          if (runningTotal + length + extra <= characterLimit) {
            acc.show.push(skill)
          } else {
            acc.hide.push(skill)
          }

          count += 1
          runningTotal += length
          return acc
        },
        { show: [], hide: [] }
      )
    } else {
      return { show: this.sortedSkills, hide: [] }
    }
  }

  isFocusSkill(skill: Skill) {
    return this.focusSkillIds.includes(skill.id)
  }

  showSeparator(index: number) {
    return index < this.skills.length - 1
  }

  tagText(skill: Skill, truncate: boolean) {
    const name = skill.name
    if (!this.characterLimit || !truncate) return name

    let length =
      this.characterLimit - this.spacingCharLength - this.ellipsisCharLength

    if (this.isFocusSkill(skill)) length -= this.focusStarCharLength

    if (this.skillCount > 1) {
      length -= this.hiddenCountCharLength + this.separatorCharLength
    }

    return `${name.slice(0, length).trim()}...`
  }

  private get sortedSkills() {
    return this.skills.slice().sort((a, b) => {
      if (this.isFocusSkill(a) && !this.isFocusSkill(b)) return -1

      if (!this.isFocusSkill(a) && this.isFocusSkill(b)) return 1

      return a.name.localeCompare(b.name)
    })
  }

  private get skillCount() {
    return this.skills.length
  }
}
