import { Tooltip } from 'src/design-system'
import { Skill } from 'app/packs/src/contracts/skill'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { SkillTag } from './skill-tag'
import { SkillTagsVm } from './skill-tags-vm'

export type SkillTagsProps = {
  characterLimit?: number
  focusSkillIds: string[]
  showLinks?: boolean
  skills: Skill[]
  className?: string
}

export const SkillTags = observer((props: SkillTagsProps) => {
  const {
    className,
    characterLimit,
    focusSkillIds,
    showLinks = false,
    skills,
  } = props

  const vm = React.useMemo(() => {
    return new SkillTagsVm(focusSkillIds, skills, characterLimit)
  }, [focusSkillIds, skills, characterLimit])

  const mappedSkills = vm.mappedSkills
  const shownSkills = mappedSkills.show
  const hiddenSkills = mappedSkills.hide

  if (skills.length === 0) return null

  return (
    <div
      className={cn(
        'flex flex-row flex-wrap items-center text-theme-50 text-xs',
        className
      )}
    >
      {shownSkills.length > 0 && (
        <>
          {shownSkills.map((skill, index) => (
            <SkillTag
              key={skill.id}
              showLink={showLinks}
              showSeparator={vm.showSeparator(index)}
              skill={skill}
              vm={vm}
            />
          ))}
          {hiddenSkills.length > 0 && <HiddenSkillsTag skills={hiddenSkills} />}
        </>
      )}
      {characterLimit &&
        shownSkills.length === 0 &&
        hiddenSkills.length > 0 && (
          <TruncatedSkillTags
            hiddenSkills={hiddenSkills}
            showLinks={showLinks}
            vm={vm}
          />
        )}
    </div>
  )
})

const HiddenSkillsTag: React.VFC<{
  skills: Skill[]
}> = (props) => {
  const { skills } = props

  const content = skills.map((skill) => <div key={skill.id}>{skill.name}</div>)

  return (
    <Tooltip content={content}>
      <span className="appearance-none leading-none text-gray-500">
        +{skills.length}
      </span>
    </Tooltip>
  )
}

const TruncatedSkillTags: React.VFC<{
  hiddenSkills: Skill[]
  showLinks: boolean
  vm: SkillTagsVm
}> = (props) => {
  const { hiddenSkills, showLinks, vm } = props

  const skill = hiddenSkills[0]
  const otherSkills = hiddenSkills.slice(1)
  const moreThanOneSkill = otherSkills.length > 0

  return (
    <>
      <SkillTag
        showLink={showLinks}
        showSeparator={moreThanOneSkill}
        skill={skill}
        truncate={true}
        vm={vm}
      />
      {moreThanOneSkill && <HiddenSkillsTag skills={otherSkills} />}
    </>
  )
}
