import { Link, Tooltip } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { Star } from '@phosphor-icons/react'
import * as React from 'react'
import { Skill } from 'app/packs/src/contracts/skill'
import { SkillTagsVm } from './skill-tags-vm'

export type SkillTagProps = {
  showLink: boolean
  showSeparator: boolean
  skill: Skill
  truncate?: boolean
  vm: SkillTagsVm
}

export const SkillTag = observer((props: SkillTagProps) => {
  const { showLink, showSeparator, skill, truncate, vm } = props

  return (
    <>
      {showLink && (
        <Link
          className="p-0 text-xs flex items-center text-gray-500"
          href={`/users/skills/${skill.slug}`}
          data-turbo-frame="content"
          data-turbo-action="advance"
          onClick={(e) => e.stopPropagation()}
          underline={false}
        >
          <SkillTagContent skill={skill} truncate={truncate} vm={vm} />
        </Link>
      )}
      {!showLink && (
        <SkillTagContent skill={skill} truncate={truncate} vm={vm} />
      )}
      {showSeparator && <span className="w-1.5"></span>}
    </>
  )
})

const SkillTagContent: React.VFC<{
  skill: Skill
  truncate?: boolean
  vm: SkillTagsVm
}> = (props) => {
  const { skill, truncate = false, vm } = props

  return (
    <div className="flex flex-shrink-0 items-center">
      {vm.isFocusSkill(skill) && (
        <>
          <Star
            aria-hidden
            className="h-3 w-3 text-theme-50 mb-0.5 mr-1 flex-shrink-0"
            weight="fill"
          />
          <span className="sr-only">Focused skill: </span>
        </>
      )}
      {truncate ? (
        <Tooltip content={skill.name}>
          <span className="appearance-none">{vm.tagText(skill, truncate)}</span>
        </Tooltip>
      ) : (
        vm.tagText(skill, truncate)
      )}
    </div>
  )
}
